export default [
  {
    path: '/profile',
    name: 'profile-user',
    meta: { role: 'ROLE_BUSINESS_USER' },
    component: () => import(/* webpackChunkName: "profile-user" */ '@/pages/profile/view/Index.vue')
  },
  {
    path: '/profile/company',
    name: 'profile-user-company-data',
    meta: { role: 'ROLE_BUSINESS_USER' },
    component: () => import(/* webpackChunkName: "profile-user" */ '@/pages/profile/view/CompanyDataPage.vue')
  },
  {
    path: '/profile/contact',
    name: 'profile-user-contact-data',
    meta: { role: 'ROLE_BUSINESS_USER' },
    component: () => import(/* webpackChunkName: "profile-user" */ '@/pages/profile/view/ContactDataPage.vue')
  },
  {
    path: '/profile/banking',
    name: 'profile-user-banking-data',
    meta: { role: 'ROLE_BUSINESS_USER' },
    component: () => import(/* webpackChunkName: "profile-user" */ '@/pages/profile/view/BankingDataPage.vue')
  },
  {
    path: '/payments',
    name: 'payments-user',
    meta: { role: 'PERM_BUSINESS_USER_PAYMENTS' },
    component: () => import(/* webpackChunkName: "payments-user" */ '@/pages/payments/view/Index.vue')
  },
  {
    path: '/profile/settings',
    name: 'settings-user',
    meta: { role: 'ROLE_BUSINESS_USER' },
    component: () => import(/* webpackChunkName: "settings-user" */ '@/pages/profile/view/SettingsPage.vue')
  },
  {
    path: '/invoice-numeration-schemas',
    name: 'invoice-numeration-schemas-user',
    meta: { role: 'PERM_BUSINESS_USER_INVOICE_NUMERATION_SCHEMA_LIST' },
    component: () => import(/* webpackChunkName: "payments-user" */ '@/pages/invoice-numeration-schema/view/Index.vue')
  },
  {
    path: '/tickets/ticket-center',
    name: 'ticket-center-user',
    meta: { role: 'PERM_BUSINESS_USER_TICKET_CENTER_USER_LIST' },
    component: () => import(/* webpackChunkName: "ticket-center-user" */ '@/pages/ticket-center/Index'),
  },
  {
    path: '/products',
    name: 'products-user',
    meta: { role: 'PERM_BUSINESS_USER_PRODUCT_LIST' },
    component: () => import(/* webpackChunkName: "products" */ '@/pages/products/Index.vue')
  },
  {
    path: '/my-accounting',
    name: 'my-accounting-user',
    meta: { role: 'PERM_BUSINESS_USER_MY_ACCOUNTING_LIST' },
    component: () => import(/* webpackChunkName: "my-accounting" */ '@/pages/my-accounting/Index.vue')
  },
  {
    path: '/invoices',
    name: 'invoices-user',
    meta: { role: 'PERM_BUSINESS_USER_INVOICE_LIST' },
    component: () => import(/* webpackChunkName: "invoices" */ '@/pages/invoices/Index.vue')
  },
  {
    path: '/clients',
    name: 'clients-user',
    meta: { role: 'PERM_BUSINESS_USER_CLIENT_LIST' },
    component: () => import(/* webpackChunkName: "clients" */ '@/pages/clients/Index.vue')
  },
  {
    path: '/delivery/:closedMonthId?',
    name: 'delivery-user',
    meta: { role: 'PERM_BUSINESS_USER_DELIVERY' },
    props: route => ({
      closedMonthId: route.params.closedMonthId,
    }),
    component: () => import(/* webpackChunkName: "delivery" */ '@/pages/delivery/Index.vue')
  },
  {
    path: '/my-notifications/:notificationId?',
    name: 'my-notifications-user',
    meta: { role: 'ROLE_BUSINESS_USER' },
    props: route => ({
      notificationId: route.params.notificationId,
    }),
    component: () => import(/* webpackChunkName: "my-notifications" */ '@/pages/my-notifications/Company.vue')
  },
  {
    path: '/my-statements',
    name: 'my-statements-user',
    meta: { role: 'PERM_BUSINESS_USER_STATEMENT_LIST' },
    component: () => import(/* webpackChunkName: "my-statements" */ '@/pages/my-statements/views/Statements.vue')
  },
  {
    path: '/forms-and-templates',
    name: 'forms-and-templates-user',
    meta: { role: 'PERM_BUSINESS_USER_FORM_LIST' },
    component: () => import(/* webpackChunkName: "my-notifications" */ '@/pages/forms-and-templates/Index.vue')
  },
]
