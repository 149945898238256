import AuthLayout from "@/layouts/AuthLayout";
export default [
  {
    path: '',
    component: AuthLayout,
    meta: { auth: false },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/auth/Login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/auth/Register.vue'),
      },
      {
        path: '/remind-password',
        name: 'remind-password',
        component: () => import(/* webpackChunkName: "remind-password" */ '@/pages/auth/ForgotPassword/RemindPassword.vue'),
      },
      {
        path: '/remind-password-message',
        name: 'remind-password-message',
        component: () => import(/* webpackChunkName: "remind-password-message" */ '@/pages/auth/ForgotPassword/RemindPasswordMessage.vue'),
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        props: route => ({
          token: route.query.token,
          email: route.query.email,
        }),
        component: () => import(/* webpackChunkName: "reset-password" */ '@/pages/auth/ResetPassword.vue'),
      },
    ],
  },
]
