export default [
  {
    path: '/admin/profile',
    name: 'profile-admin',
    meta: { role: ['ROLE_OFFICE_WORKER', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "profile-admin" */ '@/pages/admin/profile/Index.vue')
  },
  {
    path: '/admin/tickets/ticket-center',
    name: 'ticket-center-admin',
    meta: { role: ['PERM_OFFICE_WORKER_TICKET_CENTER_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "ticket-center-admin" */ '@/pages/admin/ticket-center/Index.vue'),
  },
  {
    path: '/admin/statistics',
    name: 'statistics-admin',
    meta: { role: 'ROLE_ADMIN' },
    component: () => import(/* webpackChunkName: "statistics-admin" */ '@/pages/admin/statistics/Index.vue')
  },
  {
    path: '/admin/clients/:userEmail?',
    name: 'clients-admin',
    meta: { role: ['PERM_OFFICE_WORKER_CLIENT_LIST', 'ROLE_ADMIN'] },
    props: route => ({
      userEmail: route.params.userEmail,
    }),
    component: () => import(/* webpackChunkName: "users-list-admin" */ '@/pages/admin/users/views/Clients.vue')
  },
  {
    path: '/admin/companies/',
    name: 'companies-admin',
    meta: { role: ['PERM_OFFICE_WORKER_COMPANY_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "users-list-admin" */ '@/pages/admin/users/views/Companies.vue')
  },
  {
    path: '/admin/office-workers/',
    name: 'office-workers-admin',
    meta: { role: ['PERM_OFFICE_WORKER_OFFICE_WORKER_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "users-list-admin" */ '@/pages/admin/users/views/OfficeWorkers.vue')
  },
  {
    path: '/admin/permissions/',
    name: 'permissions-admin',
    meta: { role: ['PERM_OFFICE_WORKER_PERMISSIONS_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "users-list-admin" */ '@/pages/admin/users/views/Permissions.vue')
  },
  {
    path: '/admin/groups/',
    name: 'groups-admin',
    meta: { role: ['PERM_OFFICE_WORKER_GROUP_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "users-list-admin" */ '@/pages/admin/users/views/Groups.vue')
  },
  {
    path: '/admin/users/:companyId/invoices',
    name: 'user-invoices-admin',
    meta: { role: ['PERM_OFFICE_WORKER_COMPANY_SHOW_INVOICES', 'ROLE_ADMIN'] },
    props: route => ({
      companyId: route.params.companyId,
    }),
    component: () => import(/* webpackChunkName: "user-invoices-admin" */ '@/pages/admin/users/invoices/Index.vue')
  },
  {
    path: '/admin/my-accounting/:companyId?',
    name: 'my-accounting-admin',
    meta: { role: ['PERM_OFFICE_WORKER_MY_ACCOUNTING_LIST', 'ROLE_ADMIN'] },
    props: route => ({
      companyId: route.params.companyId,
    }),
    component: () => import(/* webpackChunkName: "my-accounting-admin" */ '@/pages/admin/my-accounting/Index.vue')
  },
  {
    path: '/admin/my-notifications/:notificationId?',
    name: 'my-notifications-admin',
    meta: { role: ['ROLE_OFFICE_WORKER', 'ROLE_ADMIN'] },
    props: route => ({
      notificationId: route.params.notificationId,
    }),
    component: () => import(/* webpackChunkName: "my-notifications-admin" */ '@/pages/my-notifications/Index.vue')
  },
  {
    path: '/admin/notifications/statements',
    name: 'notification-statements-admin',
    meta: { role: ['PERM_OFFICE_WORKER_STATEMENT_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "web-notification-templates-admin" */ '@/pages/admin/notifications/statements/Statements.vue')
  },
  {
    path: '/admin/notifications/templates/notifications',
    name: 'web-notification-templates-admin',
    meta: { role: ['PERM_OFFICE_WORKER_WEB_TEMPLATE_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "web-notification-templates-admin" */ '@/pages/admin/notifications/templates/Notifications.vue')
  },
  {
    path: '/admin/notifications/templates/email',
    name: 'email-templates-admin',
    meta: { role: ['PERM_OFFICE_WORKER_EMAIL_TEMPLATE_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "email-templates-admin" */ '@/pages/admin/notifications/templates/Email.vue')
  },
  {
    path: '/admin/notifications/send/notification',
    name: 'send-web-notification-admin',
    meta: { role: ['PERM_OFFICE_WORKER_WEB_TEMPLATE_SEND', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "send-web-notification-admin" */ '@/pages/admin/notifications/send/Notification.vue')
  },
  {
    path: '/admin/notifications/send/email',
    name: 'send-email-notification-admin',
    meta: { role: ['PERM_OFFICE_WORKER_EMAIL_TEMPLATE_SEND', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "send-email-notification-admin" */ '@/pages/admin/notifications/send/Email.vue')
  },
  {
    path: '/admin/languages',
    name: 'languages-admin',
    meta: { role: ['PERM_OFFICE_WORKER_LANGUAGE_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "languages-admin" */ '@/pages/admin/languages/Index.vue'),
  },
  {
    path: '/admin/languages/:locale',
    name: 'languages-edit-admin',
    props: route => ({ locale: route.params.locale }),
    meta: { role: ['PERM_OFFICE_WORKER_LANGUAGE_EDIT', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "languages-edit-admin" */ '@/pages/admin/languages/Edit.vue'),
  },
  {
    path: '/admin/forms-and-templates',
    name: 'forms-and-templates-admin',
    meta: { role: ['PERM_OFFICE_WORKER_FORM_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-admin" */ '@/pages/admin/forms-and-templates/Index.vue')
  },
  {
    path: '/admin/forms-and-templates/categories',
    name: 'forms-and-templates-categories-admin',
    meta: { role: ['PERM_OFFICE_WORKER_FORM_CATEGORY_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-categories-admin" */ '@/pages/admin/forms-and-templates/Categories.vue')
  },
  {
    path: '/admin/services-and-payments/services',
    name: 'services-admin',
    meta: { role: ['PERM_OFFICE_WORKER_SERVICE_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-admin" */ '@/pages/admin/services-and-payments/views/Services.vue')
  },
  {
    path: '/admin/services-and-payments/company-cart',
    name: 'company-cart-admin',
    meta: { role: ['PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-admin" */ '@/pages/admin/services-and-payments/views/CompanyCart.vue')
  },
  {
    path: '/admin/services-and-payments/payments-history',
    name: 'payments-history-admin',
    meta: { role: ['PERM_OFFICE_WORKER_PAYMENT_LIST', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-admin" */ '@/pages/admin/services-and-payments/views/PaymentsHistory.vue')
  },
  {
    path: '/admin/utils/exchange-rates',
    name: 'utils-exchange-rates-admin',
    meta: { role: ['PERM_OFFICE_WORKER_UTILS_EXCHANGE_RATES', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "forms-and-templates-admin" */ '@/pages/admin/utils/views/ExchangeRates.vue')
  },
  {
    path: '/admin/utils/dictionaries/vat-exemption',
    name: 'utils-dictionaries-vat-exemption-admin',
    meta: { role: ['PERM_OFFICE_WORKER_UTILS_DICTIONARIES', 'ROLE_ADMIN'] },
    component: () => import(/* webpackChunkName: "utils-dictionaries-vat-exemptions" */ '@/pages/admin/utils/views/VatExemption.vue')
  },
]
